<template>
  <div v-loading="detailsLoading">
    <form-create
      ref="form"
      v-model="formData"
      :inline="false"
      label-width="180px"
      :label-position="'right'"
      :components="components"
      @update="handleFormUpdate"
    ></form-create>

    <el-button-group
      style="padding-top: 15px; display: flex; justify-content: flex-end"
    >
      <el-button type="primary" :disabled="loading" @click="submitForm()">{{
        formData.id > 0 ? " 保 存 " : "下一步"
      }}</el-button>
    </el-button-group>
  </div>
</template>

<script>
var dayjs = require("dayjs");
export default {
  name: "teachingCourseBase",
  props: ["course_id"],
  data() {
    var _this = this;
    return {
      dayjs,
      formData: {},
      courseInfo: {},
      loading: false,
      detailsLoading: false,
      components: [
        //         {name:'el-row' ,components:[
        //             {name:'el-col',props:{span:12},components:[
        //                 {name:'form-item',props:{ prop:'cid',label:'类别' ,required:true,type:'select', data:{ name:"course", keys:"cate",isAll:false }}}
        //               ]},
        //             {name:'el-col',props:{span:12},components:[
        //                 {name:'form-item',props:{ prop:'tid',label:'类型' ,required:true,type:'select', data:{ name:"course", keys:"type",isAll:false }}}
        //               ]}
        //           ]},

        {
          name: "el-row",
          components: [
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "course_name",
                    label: "课程名称",
                    required: true,
                  },
                },
              ],
            },
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "class_hour",
                    label: "课时",
                    required: true,
                    type: "number",
                    rules: [
                      {
                        validator: (rule, value, callback) => {
                          if (!(value > 0)) {
                            return callback(new Error("请输入课时"));
                          }
                        },
                      },
                    ],
                  },
                  callback(c){
                  c.props.defaultValue=_this.formData.class_hour
               }
                },
              ],
            },
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: { prop: "course_address", label: "地点" },
                },
              ],
            },
          ],
        },

        {
          name: "el-row",
          components: [
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "cid",
                    label: "课程类别",
                    required: true,
                    type: "select",
                    data: { name: "course", keys: "cate", isAll: false },
                  },
                },
              ],
            },
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "is_free",
                    required: true,
                    label: "课程模式",
                    type: "radio",
                    data: [
                      { label: "收费", value: 1 },
                      { label: "免费", value: 0 },
                    ],
                  },
                  callback(c) {
                    // c.props.disabled = _this.courseInfo.id ? true : false;
                  },
                },
              ],
            },
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "price",
                    required: true,
                    label: "课程价格",
                    type: "number",
                    step: 0.01,
                  },
                  callback(c) {
                    c.props.defaultValue = _this.courseInfo.price;
                  },
                  slots: { after: "元" },
                  condition: "{{is_free}} == 1",
                },
              ],
            },
          ],
        },

        // {
        //   name: "form-item",
        //   props: {
        //     prop: "lecturer_id",
        //     label: "创建人",
        //     required: true,
        //     disabled: true,
        //     type: "select",
        //     data: {
        //       // url: "/xapi/user/teachList",
        //       url: "/xapi/user.user/teachList",
        //       labelOptions: { value: "id", label: "showname" },
        //     },
        //   },
        // },

        {
          name: "el-row",
          components: [
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "learn_mode",
                    required: true,
                    label: "学习模式",
                    type: "radio",
                    data: [
                      { label: "自学模式", value: 1 },
                      { label: "班课模式", value: 2 },
                    ],
                  },
                  callback(c) {
                    c.props.disabled = _this.courseInfo.id ? true : false;
                  },
                },
              ],
            },
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  condition: "{{learn_mode}} == 2",

                  props: {
                    prop: "is_group",
                    required: true,
                    label: "是否同步创建同名团队",
                    type: "radio",
                    data: [
                      { label: "是", value: 1 },
                      { label: "否", value: 0 },
                    ],
                  },
                  callback(c) {
                    c.props.disabled = _this.courseInfo.id ? true : false;
                  },
                },
              ],
            },
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  condition: "{{is_group}} == 0 && {{learn_mode}} == 2",
                  props: {
                    prop: "group_id",
                    label: "选择团队",
                    type: "select",
                    data: {
                      url: "/xapi/user.group/list",
                      params: { type: 1, is_bind: 2 },
                      labelOptions: { value: "id", label: "name" },
                    },
                  },
                  callback(c) {
                    c.props.hidden =
                      _this.courseInfo.learn_mode == 1 ||
                      _this.courseInfo.is_group == 0;
                  },
                },
              ],
            },
          ],
        },

        {
          name: "form-item",
          props: { prop: "group_name", label: "已绑定的团队名称" },
          callback(c) {
            c.props.hidden =
              !_this.courseInfo.id ||
              _this.courseInfo.is_group == 1 ||
              _this.courseInfo.learn_mode == 1;
            c.props.disabled = _this.courseInfo.id > 0;
          },
        },

        {
          name: "el-row",
          components: [
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "pid",
                    label: "教案",
                    type: "select",
                    data: {
                      url: "/xapi/course.course/myPlanList",
                      labelOptions: { value: "id", label: "course_name" },
                    },
                  },
                  callback(c) {
                    c.props.disabled =
                      _this.course_id > 0 && _this.courseInfo.pid > 0;
                  },
                  nativeOn: {
                    change() {
                      console.log("change ----- ", ...arguments);
                    },
                  },
                },
              ],
            },
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "tid",
                    label: "课程类型",
                    required: true,
                    type: "select",
                    data: { name: "course", keys: "type", isAll: false },
                  },
                  callback(c) {
                  // c.props.hidden = (_this.formData.pid || 0) > 0;
                  // c.props.required = (_this.formData.pid || 0) > 0?false:true;
                },
                },
              ],
            },
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "cover",
                    required: true,
                    label: "课程封面",
                    type: "upload",
                    listType: "picture-card",
                    limit: 1,
                  },
                  callback(c) {
                  // c.props.hidden = (_this.formData.pid || 0) > 0;
                  // c.props.required = (_this.formData.pid || 0) > 0?false:true;
                  // c.props.cover = (_this.formData.pid || 0) > 0?false:true;

                },
                },
              ],
            },
          ],
          hidden() {
            // return (_this.formData.pid || 0) > 0;
          },
        },
        {
          name: "el-row",
          components: [
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "enroll_start_date",
                    required: true,
                    label: "报名开始时间",
                    type: "datetime",
                    maxTime() {
                      return _this.formData.enroll_end_date || "";
                    },
                    minTime() {
                      return dayjs().format("YYYY-MM-DD HH:mm:ss") || "";
                    },
                  },
                },
              ],
            },
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "enroll_end_date",
                    // required: true,
                    label: "报名结束时间",
                    type: "datetime",
                    minTime() {
                      return _this.formData.enroll_start_date || "";
                    },
                  },
                },
              ],
            },
            {
              name: "el-col",
              props: { span: 8 },
              components: [
              {
          name: "form-item",
          props: { prop: "pass_grade", label: "通过得分", type: "number" },
          callback(c) {
            c.props.defaultValue = 60;
          },
        },
              ],
            },
          ],
          hidden() {
            // return (_this.formData.id || 0) > 0;
          },
        },
        {
          name: "el-row",
          components: [
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "course_start_date",
                    required: true,
                    label: "课程开始时间",
                    type: "datetime",
                    maxTime() {
                      return _this.formData.course_end_date || "";
                    },
                    minTime() {
                      return _this.formData.enroll_start_date || "";
                    },
                  },
                },
              ],
            },
            {
              name: "el-col",
              props: { span: 8 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "course_end_date",
                    // required: true,
                    label: "课程结束时间",
                    type: "datetime",
                    minTime() {
                      let { course_start_date, enroll_end_date } =
                        _this.formData;
                      return Math.max(
                        course_start_date
                          ? new Date(course_start_date).getTime()
                          : 0,
                        enroll_end_date
                          ? new Date(enroll_end_date).getTime()
                          : 0
                      );
                    },
                  },
                },
              ],
            },
            {
              name: "el-col",
              props: { span: 8 },
              components: [
              {
          name: "form-item",
          props: { prop: "sort", label: "排序权重", type: "number", min: 0 },
        },
              ],
            },
          ],
          hidden() {
            // return (_this.formData.id || 0) > 0;
          },
        },
        {
          name: "form-item",
          props: {
            prop: "description",
            label: "课程简介",
            type: "editor",
            editorConfig: { maximumWords: 2000 },
            style: { position: "relative", zIndex: 0 },
          },
        },

        // {name:'form-item',props:{prop:'cover',required:true,label:'封面',type:'upload', listType:'picture-card',limit:1}
        //    ,hidden(){
        //     return (_this.formData.pid || 0) > 0;
        //   }},

        // {
        //   name: "form-item",
        //   props: {
        //     prop: "class_teach_ids",
        //     label: "班主任",
        //     multiple: true,
        //     type: "select",
        //     data: {
        //       // url: "/xapi/user/teachList",
        //       url: "/xapi/user.user/teachList",
        //       labelOptions: { value: "id", label: "work_show_name" },
        //     },
        //     notice: "注：班主任可以设置多人，创建人为第一班主任",
        //     dataMethod: (data) => {
        //       for (let i = 0; i < data.length; i++) {
        //         if (data[i].id + "" === this.userInfo.id + "") {
        //           data[i].disabled = true;
        //         }
        //       }
        //       return data;
        //     },
        //   },
        // },

       
        // {
        //   name: "form-item",
        //   props: { prop: "student_num", label: "学生上限人数", type: "number" },
        //   callback(c) {
        //     c.props.defaultValue = 100;
        //   },
        // },
        // {
        //   name: "form-item",
        //   props: { prop: "teach_num", label: "教师上限人数", type: "number" },
        //   callback(c) {
        //     c.props.defaultValue = 10;
        //   },
        // },

       
        
      ],
      isOnlyField: false,
      editProps: {},
    };
  },
  methods: {
    handleFormUpdate({ prop, value, vNode }) {
      var selected = null;
      // console.log(this.editProps["course_name"],"55555555")
      // if (prop === "pid" && this.editProps["course_name"] !== true) {
      if (prop === "pid" ) {
        console.log('1111')
        selected = vNode.parseData.filter((d) => d.value === value)[0] || null;
        if (selected) {
          // console.log(selected,'selected')
          
          this.formData.course_name =this.formData.course_name?this.formData.course_name: selected.course_name;
          this.formData.cover = selected.cover;
          this.formData.description = selected.description;
          this.formData.cid = selected.cid;
          this.formData.tid = selected.tid;
        }

      }
      this.editProps[prop] = value ? true : false;
    },
    routeUpdate() {
      this.getDetail();
    },
    getDetail() {
      console.log(this.course_id, "this.course_id");
      if (this.course_id > 0) {
        this.loading = true;
        this.detailsLoading = true;
        this.$http
          // .post("/xapi/course.course/detail", { id: this.course_id })
          .post("/xapi/course.course/detail_simplen", { id: this.course_id })
          .then(({ data, isOnlyField }) => {
            this.detailsLoading = false;
            data.is_free =
              data.is_free === undefined
                ? data.price > 0
                  ? 1
                  : 0
                : data.is_free;
            this.formData = data;
            this.courseInfo = data;

            this.isOnlyField =
              isOnlyField === true || isOnlyField === undefined;
          })
          .finally(() => {
            this.loading = false;
            this.detailsLoading = false;
          });
      } else {
        this.formData = { lecturer_id: this.userInfo.id };
      }
    },
    submitForm() {
      this.$refs.form.validate((form, valid) => {
        if (valid) {
          this.loading = true;
          form.student_num=this.courseInfo.student_num?this.courseInfo.student_num:100
          form.teach_num=this.courseInfo.teach_num?this.courseInfo.teach_num:10
          this.$http
            .post("/xapi/course.course/save", {
              id: this.course_id,
              ...form,
              price:
                form.is_free == 0
                  ? 0
                  : Math.floor(Number(form.price) * 100) / 100,
            })
            .then(({ data, msg }) => {
              // console.log(data, "dataaaaaaaaaa");
              // return
              this.$handleMessage(msg || "保存成功", "success", () => {
                if (!(this.course_id > 0)) {
                  console.log("chuangjiankecccccccccccccccccc");
                  this.$handleRoute(
                    { course_id: data.id },
                    "teachingCourseStudy"
                  );
                } else {
                  this.$handleRoute(
                    { course_id: this.course_id },
                    "teachingCourseStudy"
                  );
                }
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }, this.isOnlyField);
    },
  },
};
</script>

<style scoped>
</style>